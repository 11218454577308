@import "./functions";

$primaryColor: #ee8f61;
$secondaryColor: #16786c;

$white: #ffffff;
$mineShaft: #2d2d2d;
$primaryText: #333333;
$graySpecial: #575757;
$gray: #818181;

$payModalPrimaryColor: $primaryColor;
$specialOfferPrimaryColor: $secondaryColor;
$specialOfferHeaderColor: #d9eae8;

$trans: transparent;

//shadow & opacity
$blackWithOpacity: rgba(0, 0, 0, 0.5);
$grayOpacity: rgba(45, 45, 45, 0.4);
$boxShadowColor: rgba(183, 181, 170, 0.4);
$boxShadow: 0 1px 4px 0 rgba(183, 181, 170, 0.4);
$boxShadowTitle: 0 1px 2px rgba(0, 0, 0, 0.2);
$shadowColor: rgba(183, 181, 170, 0.3);

// links
$linkColor: #2879d8;

// font-size
$fontSizeHtml: 100%; // 62.5% of 16px = 10px
$fontSize9: rem(9); // 9px
$fontSize10: rem(10); // 10px
$fontSize11: rem(11); // 11px
$fontSize12: rem(12); // 12px
$fontSize13: rem(13); // 13px
$fontSize14: rem(14); // 14px
$fontSize15: rem(15); // 15px
$fontSize16: rem(16); // 16px
$fontSize17: rem(17); // 17px
$fontSize18: rem(18); // 18px
$fontSize20: rem(20); // 20px
$fontSize22: rem(22); // 22px
$fontSize24: rem(24); // 24px
$fontSize26: rem(26); // 26px
$fontSize28: rem(28); // 28px
$fontSize30: rem(30); // 30px
$fontSize32: rem(32); // 32px
$fontSize34: rem(34); // 34px
$fontSize38: rem(38); // 38px
$fontSize40: rem(40); // 40px
$fontSize44: rem(44); // 44px
$fontSize48: rem(48); // 48px
$fontSize50: rem(50); // 50px

$fwn: 400; // font weight normal
$fwsb: 500; // font semi bold
$fwb: 600; // font weight bold
$fwstb: 700; // font weight bold
$fwexb: 800; // font weight extra bold

$fontFamily: Open Sans, sans-serif;

$transition: all 0.3s ease;

// button
$buttonBackground: $primaryColor;
$buttonFontFamily: $fontFamily;
$buttonFontSize: $fontSize22;
$buttonFontWeight: $fwb;

// title
$titleH1Color: $primaryColor;
$titleH1FontSize: $fontSize26;
$titleH1FontFamily: $fontFamily;
$titleH1FontWeight: $fwb;

$titleH2Color: $primaryColor;
$titleH2FontSize: $fontSize24;
$titleH2FontFamily: $fontFamily;
$titleH2FontWeight: $fwb;

$titleH3Color: $mineShaft;
$titleH3FontSize: $fontSize22;
$titleH3FontFamily: $fontFamily;
$titleH3FontWeight: $fwb;

// preloader
$preloaderColor: $primaryColor;
$preloaderBg: rgba(255, 255, 255, 0.5);

// subscription
$plansPrimaryBg: $white;
$plansInactiveBg: $white;
$plansPrimaryColor: $secondaryColor;
$plansInactiveColor: lighten(desaturate($plansPrimaryColor, 40), 60);
$plansPrimaryBorderColor: $secondaryColor;
$plansInactiveBorderColor: $trans;
$plansPrimaryTitleColor: $secondaryColor;
$plansInactiveTitleColor: inherit;
$plansPrimaryTextColor: $titleH3Color;
$plansInactiveTextColor: inherit;
$plansPrimaryPriceColor: $white;
$plansInactivePriceColor: lighten(desaturate($plansPrimaryColor, 40), 20);
$plansBadgeColor: $primaryColor;
$fixedButtonBoxBg: $white;
$fixedButtonDiscountColor: $secondaryColor;
$policyCheckBoxTextColor: $mineShaft;
$reservedPriceColor: $secondaryColor;

$terminateBg: none;
$terminateTitleColor: $primaryColor;
$terminateTextColor: $mineShaft;
$terminateInputBg: #f7f7f7;
$terminateInputColor: inherit;
